/* Modal.css */
.modal-overlay-1 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
  height: 100%;
  background: rgba(18, 18, 18, 0.6);
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  backdrop-filter: blur(5px);
}

.modal-1 {
  position: relative;
  background: #1A1A1C !important;
  display: flex !important;
  flex-direction: column;
  justify-content: center !important;
  align-items: center !important;
  max-height: 82vh !important;
  padding:25px 0px;
  width: 50vw !important;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1; /* Ensure the modal appears above the overlay */
}


.cancel-icon{
  float: right;
  background-color: #121212B2;
  padding: 9px 12px;
  border-radius: 50%;
  height: 42px;
  width: 42px;
  position: absolute;
  top: 20px;
  right: 20px;
}
.cancel-icon svg{
  margin-top: -3px;
}