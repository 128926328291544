
* {
  font-family: Open Sans, sans-serif;
}
.homepage-section {
  width: 100%;
  background-color: #121212;
  color: #121212;
  /* min-height: 700px; */
  height: 100vh;
  overflow: hidden;
}
.row > * {
  padding-left: unset !important;
}
.content {
  margin-left: 30px;
}
.homepage-footer .footer .footer-wrapper,
.homepage-footer .footer .footer-content ul li {
  color: #8e929a !important;
  font-size: 10px;
}
.homepage-footer .footer {
  padding: 5px 35px;
}
.content-container {
  width: calc(100% - 250px) !important;
}

.sidebar-container {
  width: 100% !important;
  max-width: 250px !important;
  height: 100vh;
  min-height: 100%;
  background-color: rgb(26, 26, 28);
  padding: 0px !important;
  overflow-y: auto;
}

@media only screen and (max-width: 767px) {
  .sidebar-container {
    max-width: 80px !important;
    padding: 0px !important;
  }

  .form-image-right{
    display: none !important;
  }

  .content-container {
    width: calc(100% - 80px) !important;
  }

  .sidebar-upper-section {
    display: none !important;
  }

  .divider-image {
    display: none;
  }

  .no-pointer-cursor {
    display: none;
  }

  .tab-items {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .active-tab {
    margin-right: 0px !important;
  }

  .list-area li a {
    margin-right: 0px !important;
  }
  .s-t-h:hover{
    margin-right: 0px !important;
  }
}
