.ticket-footer-wrapper .ticket-finish .spinner-border{
    --bs-spinner-width: 1.2rem;
    --bs-spinner-height: 1.2rem;
    color: black !important;
  }
.ticket-finish .spinner-border{
    --bs-spinner-width: 1.2rem;
    --bs-spinner-height: 1.2rem;
     color: black !important;
  }

.f-edit-benefit .react-toggle-thumb {
  height: 19.16px !important;
  top: 2.5px !important;
  width: 19.16px !important;
  left: 2px !important;
}
.f-edit-benefit .react-toggle--checked .react-toggle-thumb{
  left: 18px !important;
}
.f-edit-benefit{
  margin-top: none !important;
}
.longitude input:focus{
  outline: 1px solid #BBA383;
  border: none !important;
}