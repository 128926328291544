.click-here {
  color: #bba383;
  font-size: 10px;
  cursor: pointer;
  font-weight: 600;
  text-decoration-line: underline;
}

.header-img {
  color: #fff;
  margin-top: 25px;
  text-align: center;

  font-size: 14px;

  font-weight: 400;
}

.content-img {
  color: #8e929a;

  text-align: center;
  font-family: Open Sans,sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.img-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px dashed #8E929A;
  background-color: #1F1F22 !important;
  border-radius: 6px !important;
  padding: unset !important;
  height: 137px;
}

.business-image {
  height: 10vw;
}

.img-list {
  list-style: none;
}

.filename {
  color: white;
  font-size: 14px;
  text-align: center;
}

/* .image-dropzone-wrapper {
 
} */

.file-info {
  color: white;
  border: 1px solid white;
  border-radius: 6px;
  padding-left: 20px !important;
  min-width: 150px;
}

.del-icon {
  background-color: #F36464;
  border-radius: 0px 5px 5px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 10px !important;

}

.input-file-name {
  padding: 10px 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;

}
.xtra{
  flex-direction: column;
}