.click-here {
  color: #bba383;
  font-size: 10px;
  cursor: pointer;
  font-weight: 600;
  text-decoration-line: underline;
}

.b-header-img {
  color: #fff;
  margin-top: 17px;
  text-align: center;

  font-size: 12px;

  font-weight: 400;
}

.content-img {
  color: #8e929a;

  text-align: center;
  font-family:  Open Sans,sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.img-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px dashed #8e929a;
  border-radius: 6px !important;
  padding: unset !important;
  height: 137px;
}

.business-image {
  height: 10vw;
}

.img-list {
  list-style: none;
}

.filename {
  color: white;
  font-size: 12px;
  text-align: center;
}

.image-dropzone-wrapper {
  /* margin-top: 0px; */
}
.file-wraps{
  width: fit-content;
}
.file-info-business {
  /* color: white;
  border: 1px solid white;
  border-radius: 6px;
  padding-left: 0px !important;
  min-width: 270px;
  width: 100%;
  height: 48px; */
  color: white;
  border: 1px solid white;
  border-radius: 6px;
  padding-left: 0px !important;
  /* min-width: 300px; */
  height: 48px;

}

.b-del-icon {
  background-color: #f36464;
  border-radius: 0px 5px 5px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 10px !important;
  cursor: pointer;
  /* margin-bottom: 2px; */
}
.b-refresh-icon {
  background-color: #313237;
  border-radius: 0px 5px 5px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 10px !important;
  cursor: pointer;
}
.b-img-name{
  font-size: 12px;
  font-weight: 400;
}
.b-img-icon{
  margin-right: 7px !important;
  
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .file-info-business {
    min-width: 130px;
    width: 100%;
    height: 48px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .file-info-business {
    min-width: 130px;
    width: 100%;
    height: 48px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1440px){
  .file-info-business {
    min-width: 150px;
    width: 100%;
    height: 48px;
  }
}

