@import "bootstrap/dist/css/bootstrap.min.css";
@import url('./assets/fonts/open-sans.css');


* {
  font-family: Open Sans,sans-serif;
}

body {

  background-color: black;
}

.w100 {
  width: 100% !important;
}



.cogo-toast.dark {
  background-color: #333 !important;
  /* Dark background color */
  color: #fff;
  /* Light text color */
}

.ct-toast-success {
  background-color: #333 !important;
  color: #fff !important;
  border: 1px solid #8a817c;

}

.ct-toast {
  background-color: #333 !important;
  color: #fff !important;
  border: 1px solid #8a817c;
}