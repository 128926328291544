.custom-select {
  position: sticky;
}

.footer__items .custom-select .select-selected:after {
  position: absolute;
  content: "";
  top: 18px;
  right: 10px;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-color: #8e929a transparent transparent transparent;
  color: #8e929a !important;
}

.footer__items .custom-select .select-selected.select-arrow-active:after {
  border-color: transparent transparent #fff transparent;
  top: 12px;
}

.footer__items .custom-select .select-selected {
  color: #8e929a;
  padding: 12px 0 12px 12px;
  border: none;
  cursor: pointer;
  user-select: none;
  width: 100px;
  display: flex;
  position: relative;
  margin-top: -15px;
}
.footer__items .custom-select .select-items div {
  color: #8e929a;
  padding: 12px 0 12px 12px;
  border: none;
  cursor: pointer;
  user-select: none;
  width: 100px;
  display: flex;
  position: relative;
}

.footer__items .custom-select .select-items {
  position: absolute;
  border-radius: 6px;
  background-color: #313237;
  top: -160px;
  left: 0;
  right: 0;
  height: auto;
  z-index: 3;
  padding: 0;
}

.footer__items .custom-select .select-hide {
  display: none;
}

.footer__items .custom-select .custom-option {
  border: none !important;
  padding: 10px 15px !important;
  display: flex;
  flex-direction: column;
}

.footer__items .custom-select .custom-option:hover,
.footer__items .custom-select .same-as-selected {
  background-color: rgba(0, 0, 0, 0.1);
}
.footer__items .custom-option:hover {
  background-color: #bba383 !important;
  color: white !important;
  border-radius: 3px;
  padding: 5px 10px;
}
.footer__items .custom-select .same-as-selected {
  background-color: #bba383 !important;
  color: white !important;
  padding: 5px 10px;
  border-radius: 3px;
}
.footer__items .select-selected {
  border-radius: 6px;
  background-color: #313237;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
  color: #8e929a;
  font-style: normal;
}

.footer__items .select-items::-webkit-scrollbar {
  width: 4px;
  height: 8px;
}

.footer__items .select-items::-webkit-scrollbar-track {
  background-color: #8e929a;
}

.footer__items .select-items::-webkit-scrollbar-thumb {
  background-color: #8e929a;
}
.records {
  font-weight: 400 !important;
  font-size: 12px !important;
}
.total-number {
  font-weight: 600 !important;
  font-size: 12px !important;
}

.result-wrapper{
  margin-right: 35px;
}
.result-wrapper span{
  font-size: 12px !important;
}
.options-wrapper{
  margin-right: 30px;
}
.page-no{
  font-size: 12px !important;
  margin-left: 20px !important;
}

.options-wrapper .custom-select .select-selected{
  color: #8e929a !important;
  font-size: 12px !important;
}