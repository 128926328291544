.input-data input {
  background-color: #1F1F22 !important;
  color: white !important;
  border: 1px solid #8e929a !important;
}
.input-data input:focus {
  background-color: transparent !important;
  color: white !important;
}
.input-data input::placeholder {
  color: #8e929a !important;
  font-size: 14px;
}
.error-color {
  color: #ff736c;
  font-size: 12px;
  margin-top: 5px;
}
.input-error{
  border: 1px solid #ff736c !important;
}