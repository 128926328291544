.change-password-section {
    width: 100%;
    background-color: #1a1a1c;
  }

  .passsword-form-section {
    background-color: #1a1a1c;
    padding: 80px 60px 20px 60px;
    min-height: 100vh;
  }
  .change-password button{
   float: right;
  }