.wrapper-select{
    z-index: 3;
}
/* Reacyt date picker styling */
.react-date-picker__inputGroup__input{
 border: none !important;
}

.react-date-picker__inputGroup__input:focus{
    outline: none !important;
   }
.dob-city .react-date-picker {
    font-size: 12px !important;
    padding-left: unset !important;
    height: 48px !important;
    border-radius: 5px !important;
    background-color: #1f1f22 !important;
}
.dob-city .react-date-picker__wrapper{
    border-radius: 5px !important;
    padding-left: 10px !important;
}
.dob-city .react-calendar__navigation{
    background-color: #1f1f22;
    margin-bottom: unset !important;
}
.dob-city .react-calendar__navigation__label__labelText , .dob-city .react-calendar__navigation__label__labelText:hover {
    color: #8e929a;
    font-weight: bold;
    font-size: 14px;
}
.dob-city .react-calendar__navigation button:enabled:hover, .react-calendar__navigation button:enabled:focus{
    background-color: #1f1f22;
}
.dob-city .react-calendar__navigation button:disabled{
    background-color: #1f1f22;
}
.dob-city .react-calendar__navigation button {
    color: #8e929a !important;
    font-size: 22px !important;
    font-weight: 600 !important;
}
.dob-city .react-calendar{
    border: unset !important;
    width: 300px;
}
.dob-city .react-calendar__viewContainer{
    background-color: #1f1f22;
}
.dob-city .react-calendar__month-view__weekdays{
    color: white !important;
    text-transform: capitalize;
    font-size: 12px;
   
}
.dob-city abbr[title]{
    -webkit-text-decoration: unset !important;
    text-decoration: none !important;
}
.dob-city .react-calendar__tile{
    color: #8e929a !important;
    font-size: 12.8px;

}
.dob-city .react-calendar__tile--active{
    background-color: white !important;
    color: black !important;
    font-weight: 700;
    border-radius: 0.3rem;
}
.dob-city .react-calendar__tile--active:enabled:hover{
    background-color: white !important;
    /* color: black !important; */
    border-radius: 0.3rem;
}
.dob-city .react-calendar__tile:enabled:hover, .react-calendar__tile:enabled:focus{
    background-color: white !important;
    /* color: black !important; */
    border-radius: 0.3rem;
}
.dob-city .react-calendar__tile--hasActive{
    background-color: white;
    color: black !important;
    font-weight: 700;
}
.dob-city .react-calendar__tile--now {
    background: white !important;
}
.dob-city .react-calendar__tile:disabled{
    background-color: #c7c7c8;
    color: black !important;
}
.dob-city .react-date-picker__inputGroup__divider, .react-date-picker__inputGroup__leadingZero{
    color: white !important;
}