.create-benefit-section {
  width: 100%;
  background-color: #1a1a1c;
}

.benefit-form-section {
  background-color: #1a1a1c;
  padding: 80px 50px 40px 80px;
  min-height: 100vh;
}

.image-section {
  position: relative;
  background-image: url("../../assets/images/form-bg.jpg");
  width: 100%;
  height: 100%;
  padding: 30px 40px;
  background-size: cover;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.benefit-form-header {
  margin-bottom: 30px !important;
  padding-right: 10px;
}

.benefit-form-header h2 {
  display: inline;
  color: white;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
}

.benefit-form-header p {
  float: right;
  display: inline;
  color: #8e929a;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.form-wrapper {
  display: flex;
}

.input-wrapper {
  margin-right: 20px;
  width: 300px;
}

.form-wrapper-3 {
  display: flex;
  margin-top: 25px;
  justify-content: space-between;
  margin-bottom: 30px !important;
}
.img-desc-cni2 {
  height: 40px;
}

.btn-area button {
  float: right;
}

.exit-icon {
  float: right;
  cursor: pointer;
  position: relative;
  z-index: 999;
}

.campaign-icon {
  position: absolute;
  bottom: 30px;
  right: 30px;
}

.image-wrapper .form-text {
  color: #8e929a !important;
  font-size: 12px !important;
  margin-left: 10px;
}

.toggle-button-wrap-b {
  display: flex;
}

.toggle-button-wrap .react-toggle-track {
  background-color: #8e929a;
  width: 40px;
  height: 24px;
}

.react-goolge-track {
  background-color: #8e929a !important;
}

.react-toggle-thumb {
  border: unset !important;
}

.react-toggle:hover .react-toggle-track {
  background-color: #8e929a !important;
}

.react-toggle--checked:hover .react-toggle-track {
  background-color: #72bd90 !important;
}

.react-toggle--checked .react-toggle-track {
  background-color: #72bd90 !important;
}

.react-toggle .react-toggle--checked:hover {
  background-color: #72bd90;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .gap-4 {
    gap: 1rem !important;
  }
  .feature-campaign-de3 {
    font-size: 16px;
    font-weight: 400 !important;
    line-height: 28px;
  }
  .form-section {
    background-color: #1a1a1c;
    padding: 60px 20px 60px 40px;
  }

  .form-wrapper div div input::placeholder {
    font-size: 12px;
    padding: unset !important;
  }

  .form-wrapper div div .i-field {
    padding: 6px 12px 7px !important;
  }

  .form-wrapper-2 div .form-control {
    font-size: 12px !important;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .feature-campaign-de3 {
    font-size: 14px;
    font-weight: 400 !important;
    line-height: 28px;
  }
  .form-wrapper div div input::placeholder {
    font-size: 14px;
    padding: unset !important;
  }

  .form-wrapper div div .i-field {
    padding: 6px 12px 7px !important;
  }

  .form-wrapper-2 div .form-control {
    font-size: 12px !important;
  }
}

@media only screen and (min-device-width: 1601px) {
  .feature-campaign-de3 {
    font-size: 16px;
    font-weight: 400 !important;
    line-height: 28px;
  }
  .input-label-f3s {
    font-size: 16px !important;
    color: white;
  }
  .form-section {
    background-color: #1a1a1c;
    padding: 100px 80px 60px 80px;
  }

  .image-section {
    height: 100vh;
  }

  .form-label {
    font-size: 16px !important;
  }
}

.adjust-height-fhbsh {
  height: 100vh;
  overflow: auto;
  background-color: #1a1a1c;
  padding: 20px;
  box-sizing: border-box;
}

/* Custom scrollbar styles */
.adjust-height-fhbsh::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.adjust-height-fhbsh::-webkit-scrollbar-track {
  background-color: #1a1a1c; /* Matches the background for a seamless look */
}

.adjust-height-fhbsh::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
  border: 2px solid #1a1a1c;
}

/* For Edge and other browsers with native scrollbar styling support */
.adjust-height-fhbsh {
  scrollbar-width: thin;
  scrollbar-color: #888 #1a1a1c;
}

.error-text-asd {
  font-size: 12px;
  color: #ff736c;
  font-weight: 400;
}

.text-area-2::placeholder {
  font-size: 12px;
  color: #8e929a;
}
.input-label-f3s {
  font-size: 14px !important;
  color: white;
  margin-left: 10px;
}

.benefits-categories-wrapper .select-show-asd,
.benefit-wrapper .select-show-asd,
.status-wrapper .select-show-asd,
.country .select-show-asd {
  height: 48px;
  border-radius: 5px;
  border: #8e929a 1px solid;
  cursor: pointer;
  color: #8e929a;
  display: flex;
  justify-content: start;
  align-items: center;
  padding-left: 15px;
  padding-right: 10px;
  font-size: 12px !important;
  background-color: #1f1f22;
}

.benefits-categories-wrapper .arrowForSelect {
  position: absolute;
  right: 1px;
  top: 8px;
}

.wrapper-select {
  position: absolute;
  border: 5px;
  max-height: 250px;
  color: #8e929a;
  font-size: 12px;
  overflow-y: scroll;
  border-radius: 3px;
  background-color: #1f1f22;
  margin-top: 8px;
}

.singleOptions-cs3 {
  height: 30px;
  display: flex;
  justify-content: start;
  align-items: center;
  cursor: pointer;
  font-size: 12px !important;
  padding-left: 15px !important;
}

.singleOptions-cs3:hover {
  background-color: #8e929a;
  border-radius: 2px;
  color: #1a1a1c;
}

.wrapper-select::-webkit-scrollbar {
  width: 1px;
  height: 8px;
}

.wrapper-select::-webkit-scrollbar-track {
  background-color: #1a1a1c;
}

.wrapper-select::-webkit-scrollbar-thumb {
  background-color: #8e929a;
}

.img-desc-cni2 {
  color: #8e929a;
  font-size: 11px;
  padding-left: 10px;
}

.temp-header {
  background-color: #1a1a1c;
  width: 100%;
  max-width: 250px;
  /* height: 100vh; */
  overflow-y: auto;
}

@media only screen and (max-width: 767px) {
  .temp-header {
    max-width: 80px;
  }
}

.input-value-cwe32a::placeholder {
  font-size: 12px;
  padding-left: 8px;
}
.input-label {
  margin-left: 5px;
}
.mb-8 {
  margin-bottom: 35px !important;
}
.mt-8 {
  margin-top: 45px !important;
}
.next-button-wrapper button {
  float: right;
}
.status-wrapper .singleOptions-cs3 {
  font-size: 12px !important;
  height: 40px !important;
  font-weight: 500;
  color: #949494;
}
.status-wrapper .singleOptions-cs3:hover {
  background-color: #bba383;
  color: white;
  font-weight: 500;
}
.benefits-categories-wrapper .singleOptions-cs3,
.benefit-wrapper .singleOptions-cs3 {
  font-size: 12px !important;
  height: 40px !important;
  font-weight: 500;
  color: #949494;
}
.benefits-categories-wrapper .singleOptions-cs3:hover,
.benefit-wrapper .singleOptions-cs3:hover {
  background-color: #bba383;
  color: white;
  font-weight: 500;
}
.toggle-button-wrap-4 {
  color: white;
  margin-left: 10px;
  display: flex;
}

/* React Toggle */

.react-toggle-track {
  width: 36.19px;
}
.react-toggle--focus .react-toggle-thumb {
  box-shadow: unset !important;
}
.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
  box-shadow: unset !important;
}

.f-benefit .react-toggle--checked .react-toggle-thumb {
  left: 18.5px !important;
}
.f-benefit .react-toggle-thumb {
  width: 19.16px !important;
  top: 2.5px !important;
  left: 3px !important;
  height: 19.16px !important;
}
.stage-bar-2 {
  position: absolute;
  width: 100%;
  top: 40px;
}
