.toast {
    --bs-toast-color: black !important;
    --bs-toast-bg: white !important;
    width: 600px !important;
    border: 1px solid white;
    border-radius: 8px;
    margin-top: 30px;
  }
  .btn-close {
    --bs-btn-close-color: white !important;
  }
  