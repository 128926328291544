.forgot-password-section {
    width: 100%;
    height: 100vh;
    background-image: url("../../assets/images/membership-bg.png");
    background-size: cover;
    position: relative;
    background-repeat: no-repeat;
    background-position: 100% 100%;
    overflow-y: auto;
  }
  .fp-header {
    color: white;
    margin-bottom: 40px;
    padding: 40px 30px 0px 30px;
  }
 
  .fp-content {
    background-color: #1a1a1c;
    border-radius: 20px;
    width: 80%; /* Set width to 100% for responsiveness */
    max-width: 440px; 
    height: auto;
    margin: 270px auto 50px; 
  }
  
  /* Media query for small screens (e.g., mobile devices) */
  @media only screen and (max-width: 576px) {
    .fp-content {
      margin: 50px auto; /* Adjust margin for smaller screens */
    }
  }
  
  @media only screen and (max-height: 800px) {
    .fp-content {
      height: auto; /* Allow the height to adjust dynamically based on content */
      max-height: 80vh; /* Set a maximum height, e.g., 80% of the viewport height */
      margin: 180px auto 0px;
    }
  }
  
  @media only screen and (max-height: 670px) {
    .fp-content {
      height: auto; /* Allow the height to adjust dynamically based on content */
      max-height: 80vh; /* Set a maximum height, e.g., 80% of the viewport height */
      margin: 80px auto 10px;
      z-index: 30;
    }
    .fp-header {
      color: white;
      margin-bottom: 25px;
      padding: 40px 30px 0px 30px;
    }
  }
  
  .fp-header img {
    width: 180px;
    height: 26.18px;
  }
  
  .fp-form-parent {
    z-index: 100;
    padding-bottom: 5px;
  }
  
  .fp-form {
    padding: 0px 30px 0px 30px;
  }
  
  .f-c-label{
    margin-bottom: 0px !important;
    padding-left: 0px !important;
  }
  
  label {
    color: white !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    margin-bottom: 15px !important;
    padding-left: 10px !important;
  }
  input {
    background-color: transparent ;
    color: white !important;
    /* height: 40x !important; */
    border: 1px solid #8e929a !important;
  }
  input:focus {
    background-color: transparent ;
    color: white !important;
  }
  input::placeholder {
    color: #8e929a !important;
    font-size: 14px;
  }
  .fp-btn-wrapper {
    margin-top: 30px;
    margin-bottom: 40px;
    border-radius: 0px 0px 6px 6px;
    text-align: center;
  }
  .fp-form-content {
    width: 100%;
    height: 100vh;
    background-image: linear-gradient(
      rgba(18, 18, 18, 1),
      rgba(18, 18, 18, 0.1),
      rgba(18, 18, 18, 0),
      rgba(18, 18, 18, 1)
    );
    z-index: 1;
    background-color: rgba(18, 18, 18, 0.6);
    background-size: cover;
    position: relative;
    background-repeat: no-repeat;
    background-position: 100% 100%;
    overflow-y: auto;
  }
  .form-text {
    display: block;
    color: #8e929a !important;
    font-size: 13px !important;
    float: right;
    font-weight: 400;
  }
  .error-color {
    color: #ff736c;
    font-size: 12px;
  }
  .footer {
    position: absolute;
    bottom: 0;
  }
  
  .fp-btn-wrapper button{
    border-radius: 6px;
    height: 40px;
    padding: 0px 120px;
    font-weight: 700;
    font-size: 12px;
  }
  .form-control:focus{
    border-color: white !important;
    box-shadow: none !important;
  }
  .fp-form input{
    background-color: #1F1F22;
    height: 48PX !important;
  }
  .forgot-password-header{
    margin-bottom: 40px;
    color: white;
    padding-left: 30px;
  }
  .forgot-password-header h6{
    font-weight: 600;
    font-size: 20px;
  }
  .email-message-text{
    font-size: 16px;
    color: rgba(255, 255, 255, 0.94);
    font-weight: 200;
    padding-left: 30px;
    padding-right: 20px;
    /* margin-bottom: 200px; */
  }
  .message-screen{
    padding-bottom: 163px;
  }

  .forgot-password-header-2{
    margin-bottom: 20px;
    color: white;
    padding-left: 30px;
  }
  .forgot-password-header-2 h6{
    font-weight: 600;
    font-size: 20px;
  }