/* Time picker container */
.time-picker-popup {
  display: flex;
  flex-direction: row;
  background-color: #1a1a1c;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 25px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  margin-top: 10px;
  margin-right: 12px;
  position: relative; /* Ensure absolute positioning of the button */
}

/* Styling for the individual time picker sections */
.time-picker-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* Label styling */
.time-label {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
  color: white;
}

/* Container for input and icon */
.time-input-container {
  position: relative;
  display: flex;
  align-items: center;
}

/* Time input styling */
.time-input {
  /* padding: 8px 12px; */
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  color: white;
  background-color: #1a1a1c;
  border: 1px solid #ccc;
  border-radius: 6px;
  outline: none;
  transition: border 0.3s ease;
  /* width: 120px; */
}

/* Hover and focus states for the time input */
.time-input:hover,
.time-input:focus {
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

/* Optional: Add transition for smooth interactions */
.time-input::placeholder {
  color: #999;
}

/* Button styling */
.set-time-button {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 6px;
  padding: 8px 16px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.set-time-button:hover {
  background-color: #0056b3;
}

/* Time picker icon */
.time-picker-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: white; /* Icon color */
  font-size: 20px;
  cursor: pointer;
}
