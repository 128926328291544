.navbar-section{
    width: 100%;
    background-color: #121212;
    padding: 30px 0px 0px 20px;
}
.breadcrumb-wrapper{
    display: inline;
}
.breadcrumb-item{
    color: white !important;
    font-size: 13px !important;
}
.breadcrumb-item a{
    text-decoration: none;
    color: white;
}
.active{
    color: #6f7278 !important;
}
a{
    text-decoration: none;
    color: white;
}
.breadcrumb-item+.breadcrumb-item::before {
    color: #6f7278 !important;
}
.icon-wrapper{
    float: right;
    display: inline;
}
.icon-list{
    list-style: none;
    display: flex;

}
.icon-list li a{
    background-color: #313237;
    border-radius: 12px;
    padding: 8px;
    margin-right: 10px;
    display: flex;
    align-items: center;
}
.icon-list li button{
    background-color: #313237;
    border-radius: 12px;
    padding: 8px;
    margin-right: 10px;
    display: flex;
    align-items: center;
}

.user-icon-wrapper{
    position: relative;
}
.user-dropdown {
    position: absolute;
    width: 100px;
    top: 70px;
    right: 35px;
    border-radius: 6px;
    color: #d4d8dd;
    background-color: #313237;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
  }
  .user-dropdown ul{
    list-style: none;
    width: 100%;
    padding: 5px 5px 10px 0px;
  }
  .logout-wrapper{
    padding: 5px 5px 5px 8px;
    border-radius: 6px;
    font-size: 12px;
    font-weight: 600;
    text-align: left;
  }
  .logout-wrapper:hover{
    color: white;
    background-color: #1a1a1c;
  }
  .pointer{
    cursor: pointer;
  }
  .dot-message{
    top: 2px;
    right: 0px;
    padding: 5px;
  }

  /* Settings Dropdown */
  .settings-dropdown {
    position: absolute;
    width: 150px;
    top: 70px;
    right: 80px;
    border-radius: 6px;
    color: #d4d8dd;
    background-color: #313237;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
    z-index: 5;
  }
  .settings-dropdown ul{
    list-style: none;
    width: 100%;
    padding: 5px 5px 10px 0px;
  }