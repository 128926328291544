.container{
    background-color: black;
    color:white;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    padding: 0;
    margin:0;
    font-size: larger;
}