.login-section {
  width: 100%;
  height: 100vh;
  background-image: url("../../assets/images/membership-bg.png");
  background-size: cover;
  position: relative;
  background-repeat: no-repeat;
  background-position: 100% 100%;
  overflow-y: auto;
}
.login-header {
  color: white;
  margin-bottom: 50px;
  padding: 40px 30px 0px 30px;
}
.login-content {
  background-color: #1a1a1c;
  border-radius: 20px;
  width: 80%; /* Set width to 100% for responsiveness */
  max-width: 440px; /* Set a maximum width if needed */
  height: auto;
  margin: 210px auto 50px; /* Center the element vertically and horizontally */
}

/* Media query for small screens (e.g., mobile devices) */
@media only screen and (max-width: 576px) {
  .login-content {
    margin: 50px auto; /* Adjust margin for smaller screens */
  }
}

@media only screen and (max-height: 800px) {
  .login-content {
    height: auto; /* Allow the height to adjust dynamically based on content */
    max-height: 80vh; /* Set a maximum height, e.g., 80% of the viewport height */
    margin: 130px auto 0px;
  }
}

@media only screen and (max-height: 670px) {
  .login-content {
    height: auto; /* Allow the height to adjust dynamically based on content */
    max-height: 80vh; /* Set a maximum height, e.g., 80% of the viewport height */
    margin: 60px auto 10px;
    z-index: 30;
  }
  .login-header {
    color: white;
    margin-bottom: 25px;
    padding: 40px 30px 0px 30px;
  }
}

.login-header img {
  width: 180px;
  height: 26.18px;
}

.login-form-parent {
  z-index: 100;
  padding-bottom: 5px;
}

.login-form {
  padding: 0px 30px 0px 30px;
}

.f-c-label{
  margin-bottom: 0px !important;
  padding-left: 0px !important;
}

label {
  color: white !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  margin-bottom: 15px !important;
  padding-left: 10px !important;
}
input {
  background-color: transparent ;
  color: white !important;
  /* height: 40x !important; */
  border: 1px solid #8e929a !important;
}
input:focus {
  background-color: transparent ;
  color: white !important;
}
input::placeholder {
  color: #8e929a !important;
  font-size: 14px;
}
.btn-wrapper {
  margin-top: 80px;
  margin-bottom: 40px;
  border-radius: 0px 0px 6px 6px;
  text-align: center;
}
.row {
  margin: unset !important;
}
.form-content {
  width: 100%;
  height: 100vh;
  background-image: linear-gradient(
    rgba(18, 18, 18, 1),
    rgba(18, 18, 18, 0.1),
    rgba(18, 18, 18, 0),
    rgba(18, 18, 18, 1)
  );
  z-index: 1;
  background-color: rgba(18, 18, 18, 0.6);
  background-size: cover;
  position: relative;
  background-repeat: no-repeat;
  background-position: 100% 100%;
  overflow-y: auto;
}
.form-text {
  display: block;
  color: #8e929a !important;
  font-size: 13px !important;
  float: right;
  font-weight: 400;
}
.error-color {
  color: #ff736c;
  font-size: 12px;
}
.footer {
  position: absolute;
  bottom: 0;
}

.btn-wrapper button{
  border-radius: 6px;
  height: 40px;
  padding: 0px 169.1px;
  font-weight: 700;
  font-size: 12px;
  border: none;
}
.form-control:focus{
  border-color: white !important;
  box-shadow: none !important;
}
.login-form input{
  background-color: #1F1F22;
  height: 48PX !important;
}
.login-button{
  color: black;
  background-color: white;
  height: 40px;
  padding: 0px 40px;
}
.btn-wrapper .spinner-border{
  border: 0.25em solid black;
  border-right-color: white;
  --bs-spinner-width: 1.5rem;
  --bs-spinner-height: 1.5rem;
}
.login-form input:focus{
  outline: 1px solid #BBA383;
  border: none !important;
}
.fp-form input:focus{
  outline: 1px solid #BBA383;
  border: none !important;
}
.reset-login-form input:focus{
  outline: 1px solid #BBA383;
  border: none !important;
}

@media (min-width: 351px) and (max-width: 575.98px) {
  .login-content {
    height: auto;
    max-height: 80vh;
    margin: 100px auto 0px;
}
.login-button{
  padding: 0px 110px !important;
}
.btn-wrapper{
  margin-bottom: 10px !important;
}
.login-form-parent{
  padding-bottom: 10px !important;
}
.footer{
  display: flex;
}
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .btn-wrapper{
    margin-bottom: 10px !important;
  }
  .login-button{
    padding: 0px 150px !important;
  }
}

@media (max-width: 350px) {
  .login-content {
    height: auto;
    max-height: 80vh;
    margin: 100px auto 0px;
}
.login-button{
  padding: 0px 80px !important;
}
.btn-wrapper{
  margin-bottom: 10px !important;
}
.login-form-parent{
  padding-bottom: 10px !important;
}
.footer{
  display: flex;
  padding: 15px 30px;
}
.footer-wrapper a{
  font-size: 7px;
}
.footer-content ul a li{
  font-size: 7px;
}
}