.notifications-section{
    height: fit-content;
    max-height: 500px;
    width: 400px;
    border-radius: 6px;
    color: #d4d8dd;
    background-color: #313237;
    position: absolute;
    right: 125px;
    top: 70px;
    z-index: 5;
    overflow-y: auto;
}

.notifications-section ul{
    padding: 5px 10px 25px 10px;
}
.notifications-section ul li h4{
    font-size: 20px;
    font-weight: 700;
    color: white;
    padding: 10px 15px ;
}
.list-notification{
    padding: 5px;
    
}
.list-notification img{
    width: 40px;
    height: 40px;
    border-radius: 12px;
    margin-left: 5px;
}
.not-description{
    font-size: 11px;
    margin-bottom: unset !important;
}
.not-name{
    font-weight: 700;
    font-size: 14px;
}
.not-message{
  font-weight: 400;
  font-size: 16px;
  margin-top: 20px;
  margin-bottom: 30px;
  color: white;
}
.not-header{
    /* position: fixed; */
    background-color: #313237;
}

.notifications-section::-webkit-scrollbar {
    width: 3px;
    height: 5px;
  }
  .notifications-section::-webkit-scrollbar-thumb {
    background-color: #d8e0e9;
  }
  .notifications-section {
    scrollbar-color: #666668;
  }
  .read-only{
    text-decoration: none !important;
    float: right;
    font-size: 10px;
    color: white;
    padding: unset !important;
  }
  .read-only:hover{
    text-decoration: underline !important;
    cursor: pointer;
  }

  .read-notification{
    background-color: #1a1a1c !important; /* White background */
    border-radius: 6px;
  }
  .not-breaker{
      margin: 10px 0 !important;
  }
  .green-signal{
    height: 8px;
    width: 8px;
    background-color:#3de17f;
    color:#3de17f;
    border-radius: 50%;
    float: right;
  }