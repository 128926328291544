.make-custom-modal-ds3 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 1000;
  overflow-y: scroll;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}
.make-custom-modal-ds3::after {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #1a1a1c;
  /*background: rgba(26, 26, 28, 0.7);*/
  z-index: -10;
  backdrop-filter: blur(5px);
}

.custom-modal-width {
  max-width: 867px !important;
  width: 100% !important;
  background-color: #1a1a1c !important;
  border-radius: 24px;
  min-height: 100vh;
}

.modelHeading-oi9 {
  font-size: 24px;
  font-weight: 700;
  line-height: 33.6px;
  color: #ffff;
}

.feature-campaign-de3 {
  font-size: 16px;
  font-weight: 400 !important;
  line-height: 28px;
  padding-left: 10px;
}

.editModalSubHeading-kid {
  font-size: 16px;
  font-weight: 700 !important;
  line-height: 12px;
  color: #ffff;
}

.line-spreader-sd {
  height: 1px;
  border-bottom: 1px solid #272b33;
}

.status-wrap .select-show-asd {
  height: 48px;
  border-radius: 5px;
  border: #8e929a 1px solid;
  cursor: pointer;
  color: #8e929a;
  display: flex;
  justify-content: start;
  align-items: center;
  font-weight: 400;
  padding-left: 15px;
  font-size: 12px !important;
  padding-right: 20px;
  background-color: #1f1f22;
}
.color-box {
  display: flex;
  gap: 10px;
}
.input_color {
  width: 100%;
  height: 100%;
}

.add-ticket-wrapper {
  position: relative;
  margin-top: 20px !important;
}
.ticket-footer-wrapper {
  display: flex;
  justify-content: end;
  gap: 10px;
  margin-top: 30px !important;
}
.selected-page-image {
  height: 416px;
  width: 100%;
  border-radius: 6px;
  object-fit: cover;
  margin-bottom: 20px;
}
.selected-feed-image {
  width: 100%;
  border-radius: 6px;
  object-fit: cover;
  margin-bottom: 20px;
  height: 286px;
}

.status-wrap .singleOptions-cs3 {
  font-size: 12px !important;
  height: 40px !important;
  font-weight: 500;
  color: #949494;
}
.status-wrap .singleOptions-cs3:hover {
  background-color: #bba383;
  color: white;
  font-weight: 500;
}

.toggle-button-wrap-3 {
  margin-top: 38px !important;
}
.toggle-button-wrap-3 .react-toggle {
  margin-top: 2px;
}
.toggle-button-wrap-2 .react-toggle--checked .react-toggle-thumb {
  left: 15px !important;
  width: 19.16px !important;
  height: 19.16px !important;
}
/* .toggle-button-wrap-2 .react-toggle .react-toggle-thumb{
  left: 15px !important;
} */
.toggle-button-wrap-2 .react-toggle-thumb {
  height: 19.16px !important;
  width: 19.16px !important;
  left: 2px !important;
  top: 2.5px !important;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .space {
    width: 100% !important;
  }
}
