.custom-modal {
  display: none;
  position: fixed;
  z-index: 40;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.8) !important;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px); 
}

.modal-dialogue {
  width: 628px;
  height: 254px;
}
.modal-title {
  color: white ;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 20px;
  text-align: center;
}
.modal-message {
  color: #8e929a;
  font-family:  Open Sans,sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  text-align: center;
}
.modal-content {
  background-color: #1a1a1c !important;
  margin: 10% auto;
  padding-top: 30px;
  width: 628px;
  color: white;
  height: 254px;
  border: 1px solid #888;
  border: none !important;
  border-radius: 24px !important;
}
.modal-body {
  flex: none !important;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.show {
  display: block !important;
}
.modal-footer-2 {
  display: flex;
  justify-content: center;
  margin: unset !important;
}
.modal-footer-2 button {
  margin-right: 20px;
  margin-top: unset !important;
}
