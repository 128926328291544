.custom-select {
  position: sticky;
}

.form-wrapper .custom-select .select-selected:after {
  position: absolute;
  content: "";
  top: 18px;
  right: 10px;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-color: #8e929a transparent transparent transparent;
  color: #8e929a !important;
}

.form-wrapper .custom-select .select-selected.select-arrow-active:after {
  border-color: transparent transparent #fff transparent;
  top: 0px;
}

.form-wrapper .custom-select .select-items div,
.form-wrapper .custom-select .select-selected {
  color: #8e929a;
  padding: 15px 12px 11px;
  cursor: pointer;
  user-select: none;
  position: relative;
  font-size: 12px !important;
  height: 48px !important;
}

.form-wrapper .custom-select .select-items {
  position: absolute;
  border-radius: 6px;
  background-color: #313237;
  top: 40px;
  left: 0;
  right: 0;
  height: auto;
  z-index: 3;
  padding: 0;
}

.form-wrapper .custom-select .select-hide {
  display: none;
}

.form-wrapper .custom-select .custom-option {
  border: none !important;
  padding: 10px 15px !important;
  display: flex;
  flex-direction: column;
}

.form-wrapper .custom-select .custom-option:hover,
.form-wrapper .custom-select .same-as-selected {
  background-color: rgba(0, 0, 0, 0.1);
}
.form-wrapper .custom-option:hover {
  background-color: #bba383 !important;
  color: white !important;
  border-radius: 3px;
  padding: 5px 10px;
}
.form-wrapper .custom-select .same-as-selected {
  background-color: #bba383 !important;
  color: white !important;
  padding: 5px 10px;
  border-radius: 3px;
}
.form-wrapper .select-selected {
  border-radius: 6px;
  background-color: #1f1f22;
  border: 1px solid #8e929a;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
  color: #8e929a;
  font-style: normal;
}

.form-wrapper .select-items::-webkit-scrollbar {
  width: 4px;
  height: 8px;
}

.form-wrapper .select-items::-webkit-scrollbar-track {
  background-color: #8e929a;
}

.form-wrapper .select-items::-webkit-scrollbar-thumb {
  background-color: #8e929a;
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .form-wrapper .custom-select .select-items div,
  .form-wrapper .custom-select .select-selected {
    color: #8e929a;
    padding: 9px 12px 10px;
    font-size: 12px !important;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .form-wrapper .custom-select .select-items div,
  .form-wrapper .custom-select .select-selected {
    color: #8e929a;
    padding: 13px 12px 10px;
    font-size: 12px !important;
  }
  .select-selected {
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
  }
  .select-items {
    z-index: 2;
  }
}
