.benefit-details-section {
  width: 100%;
  height: 100vh;
  background-color: #1a1a1c;
}
.benefit-form-2-section {
  background-color: #1a1a1c;
  padding: 80px 50px 40px 50px;
  min-height: 100vh;
}
.benefit-image {
  position: relative;
  background-image: url("../../assets/images/Step2.png");
  width: 100%;
  padding: 30px 40px;
  background-size: cover;
  height: 100vh;
}
.overlay-2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(18, 18, 18, 0.5);
}

.benefit-form-header {
  margin-bottom: 30px !important;
  padding-right: 10px;
}

.benefit-form-header h2 {
  display: inline;
  color: white;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
}
.benefit-form-header p {
  float: right;
  display: inline;
  color: #8e929a;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
.benefit-form-wrapper-3 {
  margin-top: 25px;
}
.benefit-address {
  position: relative;
  margin-top: 30px;
  margin-bottom: 25px;
}

.event-btn-area button {
  float: right;
  margin-top: 240px;
}
.exit-icon {
  float: right;
}
.campaign-icon {
  position: absolute;
  bottom: 30px;
  right: 30px;
}

/* Datepicker */
.react-datepicker-wrapper {
  display: block !important;
}
.react-datepicker__input-container .react-datepicker__calendar-icon {
  position: absolute;
  padding: 0.5rem;
  box-sizing: content-box;
  right: 0;
  z-index: 3;
  height: 20px;
  width: 20px;
  top: 6px;
}
.react-datepicker__input-container input {
  width: 100%;
  border-radius: 6px;
  height: 48px;
  border: 1px solid #8e929a;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  background-color: #1f1f22;
  position: relative;
  padding: 10px 12px 11px !important;
}
.react-datepicker__input-container input::placeholder {
  font-size: 12px !important;
}
.calender-icon {
  position: absolute;
  top: 170px;
  left: 380px;
}
.form-label {
  font-size: 16px !important;
}
.react-datepicker-popper {
  z-index: 6 !important;
}
.react-datepicker__month-container {
  background-color: #1f1f22;
  color: #8e929a !important;
  /* border: 1px solid #8e929a ; */
}
.react-datepicker__header {
  background-color: #1f1f22 !important;
  color: #8e929a !important;
}
.react-datepicker__current-month {
  background-color: #1f1f22;
  color: #8e929a !important;
}
.react-datepicker__day-names {
  background-color: #1f1f22;
  color: white !important;
  font-weight: 590;
}
.react-datepicker {
  background-color: #1f1f22;
  color: #8e929a !important;
  /* border: 1px solid #8e929a; */
  border-radius: 6px !important;
}
.react-datepicker__day-name {
  color: white !important;
  font-size: 12px;
}
.react-datepicker__day {
  color: #8e929a !important;
}
.react-datepicker__day--keyboard-selected {
  background-color: white !important;
  color: black !important;
}
.react-datepicker__day--selected {
  background-color: white !important;
  color: black !important;
}
.react-datepicker__month-container {
  width: 300px;
}
/* Time Picker */
.benefit-time-wrapper {
  position: relative;
}
.input-mask {
  width: 100%;
  display: block;
  border-radius: 6px;
  height: 48px;
  border: 1px solid #8e929a;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  padding-left: 15px;
}
.time-icon {
  float: right;
  position: absolute;
  right: 20px;
  top: 50px;
  z-index: 4;
}
.search-icon-2 {
  width: 5%;
  position: absolute;
  left: 10px;
  top: 52px;
  z-index: 4;
}

.back-btn-area {
  float: right;
  margin-right: 20px;
}
.button-wrapper {
  display: flex;
  justify-content: end;
  margin-top: 50px;
}
.next-btn-area {
  margin-right: 10px;
}

/* Dropdown */

.custom-select {
  position: sticky;
}

.benefit-form-wrapper .custom-select .select-selected:after {
  position: absolute;
  content: "";
  top: 18px;
  right: 10px;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-color: #8e929a transparent transparent transparent;
  color: #8e929a !important;
}

.benefit-form-wrapper
  .custom-select
  .select-selected.select-arrow-active:after {
  border-color: transparent transparent #fff transparent;
  top: 0px;
}

.benefit-form-wrapper .custom-select .select-items div,
.benefit-form-wrapper .custom-select .select-selected {
  color: #8e929a;
  padding: 10px 12px 11px;
  cursor: pointer;
  user-select: none;
  position: relative;
  font-size: 14px;
}

.benefit-form-wrapper .custom-select .select-items {
  position: absolute;
  border-radius: 6px;
  background-color: #313237;
  top: 40px;
  left: 0;
  right: 0;
  z-index: 3;
  padding: 0;
  overflow-y: auto;
  height: 250px;
}

.benefit-form-wrapper .custom-select .select-hide {
  display: none;
}

.benefit-form-wrapper .custom-select .custom-option {
  border: none !important;
  padding: 10px 15px !important;
  display: flex;
  flex-direction: column;
}

.benefit-form-wrapper .custom-select .custom-option:hover,
.benefit-form-wrapper .custom-select .same-as-selected {
  background-color: rgba(0, 0, 0, 0.1);
}
.benefit-form-wrapper .custom-option:hover {
  background-color: #bba383 !important;
  color: white !important;
  border-radius: 3px;
  padding: 5px 10px;
}
.benefit-form-wrapper .custom-select .same-as-selected {
  background-color: #bba383 !important;
  color: white !important;
  padding: 5px 10px;
  border-radius: 3px;
}
.benefit-form-wrapper .select-selected {
  border-radius: 6px;
  background-color: transparent;
  border: 1px solid #8e929a;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
  color: #8e929a;
  font-style: normal;
}
.longitude div .form-control::placeholder {
  font-size: 14px;
}
.longitude div .form-control {
  height: 50px;
}
.benefit-form-wrapper .select-items::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

.benefit-form-wrapper .select-items::-webkit-scrollbar-track {
  background-color: #8e929a;
}

.benefit-form-wrapper .select-items::-webkit-scrollbar-thumb {
  background-color: #8e929a;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .benefit-form-section {
    padding: 60px 20px 60px 40px;
  }
  .benefit-form-wrapper div div input::placeholder {
    font-size: 14px;
    padding: unset !important;
  }
  .react-datepicker__input-container input {
    padding: 9px 12px 10px !important;
  }
  .input-mask {
    /* padding: 9px 12px 10px; */
    background-color: #1f1f22;
  }
  .input-mask::placeholder {
    font-size: 10px !important;
  }

  .country .custom-select .select-selected {
    font-size: 12px;
    padding: 12px 12px 13px;
  }
  .longitude .input-data input {
    font-size: 12px;
  }
  .address-line-1 .input-data input,
  .address-line-2 .input-data input,
  .city .input-data input,
  .post-code .input-data input {
    padding: 9px 12px 10px !important;
  }
  .css-1q68qxs-placeholder {
    font-size: 12px;
  }
  .longitude .input-data input {
    padding: 12px 12px 13px !important;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .country .custom-select .select-selected {
    padding: 11px 12px 12px;
  }
}

@media only screen and (min-device-width: 1601px) {
  .benefit-form-section {
    padding: 100px 80px 60px 80px;
  }
  .country .custom-select .select-selected {
    padding: 11px 12px 12px;
  }
  .css-1q68qxs-placeholder {
    font-size: 14px;
  }
  .benefit-form-wrapper div div input::placeholder {
    font-size: 14px;
  }
  .country .custom-select .select-selected {
    font-size: 16px;
  }
  .react-datepicker__input-container input::placeholder {
    font-size: 12px;
  }
  .longitude .input-data input {
    font-size: 16px;
  }
  .form-label {
    font-size: 16px !important;
  }
}
.css-qbdosj-Input {
  font-size: 12px !important;
}
.css-qbdosj-Input input {
  border: none !important;
  margin-left: 23px !important;
  font-size: 12px !important;
}
.css-19bb58m input {
  margin-left: 25px !important;
  border: none !important;
  font-size: 12px !important;
}
.css-1jqq78o-placeholder {
  margin-left: 25px !important;
  font-size: 12px !important;
}
.css-166bipr-Input input {
  margin-left: 25px !important;
  border: none !important;
  font-size: 12px !important;
}
.country .singleOptions-cs3 {
  font-size: 12px !important;
  height: 40px !important;
  font-weight: 500;
  color: #949494;
}
.country .singleOptions-cs3:hover {
  background-color: #bba383;
  color: white;
  font-weight: 500;
}
.css-1cfo1cf input {
  font-size: 12px !important;
  border: none !important;
  margin-left: 25px !important;
}
.benefit-submit-button {
  background-color: white;
  color: black;
  border: none;
  border-radius: 6px;
  padding: 11px 48.6px;
  font-size: 12px;
  font-weight: 600;
  width: 137px;
  text-transform: uppercase;
}
.button-wrapper .next-btn-area .spinner-border {
  --bs-spinner-width: 1.2rem;
  --bs-spinner-height: 1.2rem;
  color: black !important;
}
.button-wrapper .ticket-finish .spinner-border {
  --bs-spinner-width: 1.2rem;
  --bs-spinner-height: 1.2rem;
  color: black !important;
}
.benefit-address .form-label {
  margin-left: 10px;
}
.b-longitude div {
  margin-bottom: unset !important;
}
.benefit-online .wrapper-select {
  background-color: #1f1f22;
  z-index: 5;
}
.react-datepicker__day--in-range {
  color: #0a84ff !important;
  background-color: #152534;
}
.react-datepicker__day--range-start {
  color: black !important;
  font-weight: 600;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  display: none;
}

.react-datepicker__input-container input:focus-visible {
  border: none !important;
  outline: 1px solid #bba383;
}
