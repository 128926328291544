.footer{
    background: transparent;
    width: 100%;
    padding: 15px 80px;
    color: white;
    z-index: 2;
}
.footer-wrapper{
    float: left;
    color: white;
    font-size: 10px;
}
.footer-content{
    float: right;
    color: white;

}
.footer-content ul{
    display: flex;
}
.footer-content ul li{
    list-style: none;
    padding-left: 50px;
    color: white;
    font-size: 10px;
}
.p-policy{
    text-decoration: none !important;
    color: white;
    font-size: 10px;
}