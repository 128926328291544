/* open-sans.css */

/* Open Sans - 200 (Light) */
@font-face {
    font-family: 'Open Sans';
    src: url('./static/OpenSans-Light.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
  }
  
  /* Open Sans - 300 (Regular) */
  @font-face {
    font-family: 'Open Sans';
    src: url('./static/OpenSans-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
  }
  
  /* Open Sans - 400 (Regular) */
  @font-face {
    font-family: 'Open Sans';
    src: url('./static/OpenSans-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
  }
  
  /* Open Sans - 500 (Semi-Bold) */
  @font-face {
    font-family: 'Open Sans';
    src: url('./static/OpenSans-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
  }
  
  /* Open Sans - 600 (Bold) */
  @font-face {
    font-family: 'Open Sans';
    src: url('./static/OpenSans-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
  }
  
  /* Open Sans - 700 (Extra-Bold) */
  @font-face {
    font-family: 'Open Sans';
    src: url('./static/OpenSans-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
  }
  