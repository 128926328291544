* {
  font-family: Open Sans, sans-serif;
}
.table-section {
  background-color: rgba(26, 26, 28, 255);
  color: white;
  border-radius: 24px;
  height: 82vh;
  margin-top: 10px;
  margin-right: 10px;
  padding: 30px 0px 0px 20px;
  overflow: auto;
  padding-right: 20px !important;
}
.table-header {
  margin-bottom: 10px;
}
.table-header h4 {
  font-size: 20px;
  display: inline;
}
.search-bar {
  float: right;
}
.search-content {
  position: relative;
}
.search-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 15px;
  color: #8e929a;
}
.search-content input {
  width: 280px;
  height: 40px;
  background-color: #313237 !important;
  padding: 0px 10px 0px 45px !important;
  color: white;
  font-size: 12px;
  margin-right: 20px;
  border: none !important;
  border-radius: 8px !important;
  outline: none;
}
.search-content input::placeholder {
  font-size: 12px;
}
.search-content input:focus {
  border: none !important;
  outline: none !important;
}
/* Table Footer*/

.table-footer .page-no {
  font-size: 11px;
  padding: 9px 0px 3px 5px;
  font-weight: 700;
  display: inline;
}
.table-footer div {
  font-size: 11px;
  font-weight: 700;
  display: inline;
}
.table-footer div span {
  font-weight: 400 !important;
}

/* Table Css */
table {
  --bs-table-striped-bg: #1a1a1c !important;
  --bs-table-bg: #1a1a1c !important;
  --bs-table-hover-bg: rgba(34, 35, 37, 1) !important;
}
.thead {
  color: #d8e0e9 !important ;
  background-color: #1a1a1c !important ;
  cursor: pointer;
}
thead tr th {
  color: #d8e0e9 !important;
  font-size: 11px;
}
.t-header {
  cursor: pointer;
  background-color: #1a1a1c !important ;
  color: #d8e0e9 !important ;
  border-bottom: 1px solid #313237 !important;
}
.table-container {
  height: 460px;
  border-radius: 10px;
  overflow-y: auto;
  margin-bottom: 20px;
  position: relative;
  margin-top: 35px !important;
  overflow: auto;
}
.table-container::-webkit-scrollbar {
  width: 4px;
  height: 5px;
}
.table-container::-webkit-scrollbar-thumb {
  background-color: #d8e0e9;
}
.table-container {
  scrollbar-color: #666668;
}
.header-container {
  position: sticky;
}
.t-body tr td {
  color: white;
  font-size: 12px;
  border-bottom: 1px solid #313237 !important;
  cursor: pointer;
  vertical-align: middle;
}
.t-body tr {
  background-color: #1a1a1c;
}
tr:hover {
  background-color: green !important;
}
.t-columns {
  position: sticky;
  top: 0;
  background-color: #f2f2f2;
  z-index: 1;
}

.edit-button {
  position: relative;
  color: #8e929a;
  background-color: #1a1a1c;
  padding: 5px 8px;
  border: none;
  border-radius: 6px;
  font-size: 12px;
  font-weight: 700;
}
tr:hover {
  background-color: rgba(34, 35, 37, 1) !important;
}

.edit-button-clicked {
  position: relative;
  color: #8e929a;
  background-color: #bba383;
  padding: 5px 8px;
  border: none;
  border-radius: 6px;
  font-size: 12px;
  font-weight: 700;
}

.is-dropdown-open .edit-button:hover {
  background-color: #bba383;
  color: white;
}
.email-data {
  font-size: 10px;
  color: #8e929a;
  display: block;
}
/* Pagination */
.pagination {
  margin-right: 30px;
  display: inline;
  margin-left: 50px;
  float: right;
  font-size: 12px;
  color: white;
}
.pagination span {
  font-size: 11px;
  cursor: pointer;
  padding-right: 25px;
  font-weight: 700;
}
.page-no {
  display: inline;
  float: right;
  margin-right: 20px;
}

/* Dropdown Menu */
.dropdown {
  position: absolute !important;
  top: 8px !important;
  z-index: 2;
  right: 10px !important;
  width: 200px;
  border-radius: 12px;
  color: #d4d8dd;
  background-color: #313237;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
  margin-right: 50px !important;
}

.dropdown-option {
  margin: 8px 8px !important;
  padding: 8px 15px;
  background-color: #313237;
  color: #d4d8dd;
  font-size: 12px;
  font-weight: 600;
}
.dropdown-option:hover {
  border-radius: 6px;
  background-color: #1a1a1c !important;
  color: white;
  font-size: 12px;
  font-weight: 600;
}
.active-option {
  border-radius: 6px;
  color: #d4d8dd;
}
/* Results */
.result {
  float: right;
}
.badge {
  padding: 8px 8px 6px 8px !important;
  border-radius: 8px !important;
  text-align: center !important;
  font-family: Open Sans,sans-serif !important;
  font-size: 12px !important;
  font-weight: 700 !important;
  text-transform: uppercase;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .table-container {
    height: 420px;
    border-radius: 10px;
    margin-bottom: 20px;
    position: relative;
    overflow: auto;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .table-container {
    height: 450px;
    border-radius: 10px;
    margin-bottom: 20px;
    position: relative;
    overflow: auto;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .table-container {
    height: 450px;
    border-radius: 10px;
    margin-bottom: 20px;
    position: relative;
    overflow: auto;
  }
}
@media only screen and (min-width: 1350px) and (max-width: 1500px) and (min-height: 850px) and (max-height: 950px) {
  .table-container {
    height: 550px;
    border-radius: 10px;
    margin-bottom: 20px;
    position: relative;
    overflow: auto;
  }
}
@media only screen and (min-width: 1441px) and (max-width: 1600px) {
  .table-container {
    height: 530px;
    border-radius: 10px;
    margin-bottom: 20px;
    position: relative;
    overflow: auto;
  }
}
@media only screen and (min-width: 1801px) {
  .table-container {
    height: 590px;
    border-radius: 10px;
    margin-bottom: 20px;
    position: relative;
    overflow: auto;
  }
}

*::-webkit-scrollbar {
  width: 4px;
  height: 5px;
}
*::-webkit-scrollbar-thumb {
  background-color: #d8e0e9;
}
* {
  scrollbar-color: #666668;
}

.download-icon {
  border: 1px solid #ffff;
  height: 40px;
  width: 40px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
}

.my-key {
  display: flex;
  flex-direction: row;
}
.name-data {
  margin-left: 20px;
  font-weight: 700;
}
.user-job {
  font-weight: 700;
}
.user-no {
  font-weight: 600;
}
.user-gender,
.user-income,
.user-city,
.user-date {
  font-weight: 600;
}
.user-age {
  font-weight: 700;
}
.Flagged {
  font-weight: 600;
}
/* CSV Dropdown */
.csv-dropdown {
  padding: 5px 10px 5px 10px;
  font-size: 12px;
  font-weight: 600;
  color: #d4d8dd;
  background-color: #313237;
  /* width: 116px;
  height: 48px; */
  /* height: 84px;  for export as pdf option*/
  position: absolute;
  top: 175px;
  right: 40px;
  border-radius: 10px;
  z-index: 2;
}
.csv-dropdown div {
  padding: 8px 5px 8px 5px;
  border-radius: 6px;
  cursor: pointer;
}

.csv-dropdown div:hover {
  background-color: #1a1a1c;
}
.pdf-container {
  margin-top: 8px;
}

.t-body tr:hover .email-hover {
  text-decoration: underline;
}

.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem 0.5rem 0 !important;
}
.t-head {
  font-weight: 700;
}
