
* {
  font-family: Open Sans, sans-serif;
}

.table-section {
  background-color: rgba(26, 26, 28, 255);
  color: white;
  border-radius: 24px;
  height: calc(100vh - 150px);
  margin-top: 10px;
  margin-right: 10px;
  padding: 30px 0px 0px 20px;
  overflow-y: scroll;
}

/* .table-header {
   margin-bottom: 40px;
} */

.table-header h4 {
  font-size: 20px;
  display: inline;
}

.search-bar {
  float: right;
}

.search-content-sb {
  position: relative;
}

.search-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 15px;
  color: #8e929a;
}
.website-link{
  text-decoration: none !important;
  font-weight: 600;
  color: rgba(255, 255, 255, 1);
  font-size: 12px;
}
.website-link:hover{
  color: rgba(255, 255, 255, 1);
}
.search-content-sb input {
  max-width: 280px;
  width: 100%;
  height: 38px;
  background-color: #313237 !important;
  padding: 0px 10px 0px 45px !important;
  color: white;
  font-size: 12px;
  /* margin-right: 20px; */
  border: none !important;
  border-radius: 8px !important;
  outline: none;
}

.search-content-sb input::placeholder {
  /* padding-left: 20px !important; */
  font-size: 12px;
}

/* Table Footer*/

.table-footer div {
  font-size: 11px;
  padding: 5px 0px 3px 5px;
  font-weight: 700;
  display: inline;
}

.table-footer div span {
  font-weight: 400 !important;
}

/* Table Css */
table {
  --bs-table-striped-bg: #1a1a1c !important;
  --bs-table-bg: #1a1a1c !important;
}

.thead {
  color: #d8e0e9 !important;
  background-color: #1a1a1c !important;
  cursor: pointer;
}

thead tr th {
  color: #d8e0e9 !important;
  font-size: 11px;
}

.t-header {
  cursor: pointer;
  background-color: #1a1a1c !important;
  color: #d8e0e9 !important;
  border-bottom: 1px solid #313237 !important;
}

.table-container {
  /* height: 460px; */
  /* height: 600px; */
  height: 100%;
  border-radius: 10px;
  overflow-y: auto;
  margin-bottom: 20px;
}

.table-container::-webkit-scrollbar {
  width: 4px;
  height: 5px;
}

.table-container::-webkit-scrollbar-thumb {
  background-color: #d8e0e9;
}

.table-container {
  scrollbar-color: #666668;
}

.t-body tr td {
  color: white;
  /* background-color: #1a1a1c; */
  font-size: 12px;
  border-bottom: 1px solid #313237 !important;
  cursor: pointer;
}
.t-body tr{
  background-color: #1a1a1c;
}


.edit-button {
  position: relative;
  color: #8e929a;
  background-color: #1a1a1c;
  padding: 5px 8px;
  border: none;
  border-radius: 6px;
  font-size: 12px;
  font-weight: 700;
}

.edit-button:hover {
  background-color: #bba383;
  color: white;
}

.email-data {
  font-size: 10px;
  color: #8e929a;
  display: block;
}

/* Pagination */
.pagination {
  margin-right: 30px;
  display: inline;
  margin-left: 50px;
  float: right;
  font-size: 12px;
  color: white;
}

.pagination span {
  font-size: 11px;
  cursor: pointer;
  padding-right: 25px;
  font-weight: 700;
}

.page-no {
  display: inline;
  float: right;
  margin-right: 20px;
}

.active-option {
  /* background-color: #1a1a1c; */
  border-radius: 6px;
  color: #d4d8dd;
}

/* Results */
.result {
  float: right;
}

.result span {
  font-weight: 400 !important;
  margin-right: 50px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .table-container {
    height: 420px;
    border-radius: 10px;
    margin-bottom: 20px;
    position: relative;
    overflow: auto;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .table-container {
    height: 450px;
    border-radius: 10px;
    margin-bottom: 20px;
    position: relative;
    overflow: auto;
  }
}

@media only screen and (min-width: 1350px) and (max-width: 1500px) and (min-height: 850px) and (max-height: 950px) {
  .table-container {
    height: 550px;
    border-radius: 10px;
    margin-bottom: 20px;
    position: relative;
    overflow: auto;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1600px) {
  .table-container {
    height: 530px;
    border-radius: 10px;
    margin-bottom: 20px;
    position: relative;
    overflow: auto;
  }
}

@media only screen and (min-width: 1801px) {
  .table-container {
    height: 590px;
    border-radius: 10px;
    margin-bottom: 20px;
    position: relative;
    overflow: auto;
  }
}

.t-business-category{
  font-weight: 700;
}
.t-business-website{
  font-weight: 600;
}
.t-business-phone{
  font-weight: 600;
}
.account-manager{
  font-weight: 700;
}
.b-city{
  font-weight: 600;
}
.b-address{
  font-size: 10px;
}
.b-linkedin{
  font-weight: 600;
}
.b-instagram{
  font-weight: 600;
}
.linkedin-url{
  font-weight: 600;
  font-size: 12px;
  text-decoration: none !important;
  color: rgba(255, 255, 255, 1);

}