.sidebar-upper-section {
  background-color: #1a1a1c;
  position: sticky;
  top: 0;
  padding: 12% 0px 12% 13%;
  width: 100%;
  color: white;
  overflow-x: hidden;
  z-index: 100;
}

.tab-items {
  display: flex;
}

.sidebar-section {
  background-color: #1a1a1c;
  overflow-y: auto;
  max-height: calc(
    100vh - 140px
  ); /* Adjust this to account for the upper section and divider height */
  padding: 0px 15px 30px 9%;
  width: 100%;
  color: white;
}

.sidebar-footer {
  color: #8e929a;
  display: flex;
  padding-top: 20px;
  width: 100%;
  justify-content: center !important;
  font-size: 10px;
  text-align: center;
}

.logo-img {
  width: 8rem;
  height: 17.45px;
  cursor: pointer;
}
.list-area {
  list-style: none;
  padding: unset !important;
  font-size: 12px;
  margin-top: 30px;
}
.list-area li span {
  margin-top: 5px;
}
.list-area li h6 {
  font-size: 13px;
  font-weight: 600;
  color: white;
}
.list-area li {
  padding: 12px 8%;
  cursor: pointer;
  color: #d4d8dd;
  margin-top: 8px;
}
.list-area li.active-tab a {
  background-color: rgb(187, 163, 131);
}
.list-area li.active-tab a svg path {
  fill: #ffffff;
}
.list-area li a {
  background-color: #313237;
  border-radius: 12px;
  padding: 6px;
  margin-right: 10px;
  display: inline-block;
  aspect-ratio: 1;
}
.active-application {
  color: white !important;
}
.active-tab {
  color: white !important;
}
.application-options {
  list-style: none;
}
.applications-options li {
  padding: 5px;
  margin-right: 10px;
  background-color: #313237;
  border-radius: 12px;
  cursor: pointer;
  list-style: none;
  color: #d4d8dd;
}
.icon {
  width: 16px;
  height: 16px;
  margin-right: 15px;
}
.icon-2 {
  width: 24px;
  height: 24px;
  margin-right: 15px;
}
.application-options,
.active-tab {
  background-color: #313237;
  border-radius: 12px;
  padding: 5px;
  color: white !important;
  margin-right: 10px;
}

.s-t-h:hover {
  background-color: #2a2b30;
  border-radius: 12px;
  color: white !important;
  margin-right: 10px;
  transition: background-color 0.3s ease-out;
}

.s-t-h-subtab:hover {
  background-color: #2a2b30;
  border-radius: 8px;
  color: white !important;
  transition: background-color 0.3s ease-out;
}

.header {
  display: flex;
}
.logout {
  text-decoration: none;
  color: white;
}
.row {
  padding-left: unset !important;
}
.divider-image {
  top: 60px;
  z-index: 99;
  position: sticky;
  width: 200.25px;
  display: flex;

  justify-content: center;
  margin-left: 20px;
  margin-right: 20px;
}

/* @media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sidebar-section {
    padding: 0px 0px 10px 9%;
    border-radius: 6px;
    height: 100%;
  }
  .list-wrapper div h4 {
    margin-bottom: 20px;
  }
  .list-area {
    list-style: none;
    padding: unset !important;
    font-size: 12px;
    margin-top: 15px;
  }
  .list-area li {
    padding: 10px 2%;
    cursor: pointer;
  }

  .list-area li a {
    background-color: #313237;
    border-radius: 12px;
    padding: 5px;
    margin-right: 10px;
    display: inline-block;
    aspect-ratio: 1;
  }

  .icon {
    width: 13px;
    height: 13px;
    margin-right: 15px;
  }
  .list-area li span {
    font-size: 11px;
  }
} */
/* @media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .sidebar-section {
    padding: 0px 0px 20px 9%;
    border-radius: 6px;
    height: 100%;
  }
  .list-wrapper div h4 {
    margin-bottom: 20px;
  }
  .list-area {
    list-style: none;
    padding: unset !important;
    font-size: 12px;
    margin-top: 20px;
  }
  .list-area li {
    padding: 10px 2%;
    cursor: pointer;
  }
  .list-area li span {
    font-size: 12px;
  }
  .icon {
    width: 14px;
    height: 14px;
    margin-right: 15px;
  }

  .list-area li a {
    background-color: #313237;
    border-radius: 12px;
    padding: 6px;
    margin-right: 10px;
    display: inline-block;
    aspect-ratio: 1;
  }
} */
@media only screen and (min-width: 1601px) {
  .sidebar-section {
    padding: 0px 0px 30px 9%;
    border-radius: 6px;
    height: 100%;
    /* margin-top: 60px; */
    /* margin-bottom: 45px; */
    /* height: 90vh; */
  }
  .header {
    font-size: 32px;
  }
  .icon-2 {
    /* margin-top: 6px; */
    width: 26px;
    height: 26px;
  }
  .list-wrapper div h4 {
    margin-bottom: 30px;
  }
  .list-area {
    list-style: none;
    padding: unset !important;
    font-size: 13px;
    margin-top: 30px;
  }
  .list-area li {
    padding: 12px 8%;
    cursor: pointer;
  }

  .list-area li a {
    background-color: #313237;
    border-radius: 12px;
    padding: 6px;
    margin-right: 10px;
    display: inline-block;
    aspect-ratio: 1;
  }

  .icon {
    width: 18px;
    height: 18px;
    margin-right: 25px;
  }
}

li.no-pointer-cursor {
  cursor: default;
}

@media only screen and (max-width: 767px) {
  .tab-text {
    display: none;
  }
}
