.wrapper-select{
    background-color: #313237;
    color: #949494;
}

.singleOptions-cs3:hover{
    background-color: #BBA383;
    color: white;
}
.singleOptions-cs3{
    height: 40px;
    font-size: 14px !important;
}
.select-show-asd{
    font-size: 12px;
    padding-left: 8px;
}
.arrowForSelect{
    padding-right: 10px;
    margin-top: 5px;
}
.select-font{
    font-size: 16px !important;
}

.open-outline{
    outline: 1px solid #BBA383;
    border: none !important;
}

.focused{
   background-color: #BBA383;
   border: none !important;
   color: white !important;
}
