.card {
  width: 100%;
  background-color: #121212 !important;
  padding-top: 15px;
  color: #8e929a !important;
  height: 100% !important;
}
.title-2 {
  color: white;
}
.card-title {
  font-size: 20px;
  margin-bottom: 25px !important;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .card {
    width: 100%;
    background-color: #121212;
    color: #8e929a;
  }
  .card-title {
    font-size: 16px;
  }
  .card-subtitle {
    font-size: 14px;
  }
  .title-2 {
    font-size: 24px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .card {
    width: 100%;
    background-color: #121212;
    color: #8e929a;
  }
  .card-title {
    font-size: 20px;
  }
  .card-subtitle {
    font-size: 15px;
  }
  .title-2 {
    font-size: 28px;
  }
}
@media only screen and (min-width: 1601px) {
  .card {
    width: 100%;
    background-color: #121212;
    color: #8e929a;
  }
  .card-title {
    font-size: 20px;
  }
  .card-subtitle {
    font-size: 17px;
  }
  .title-2 {
    font-size: 34px;
  }
}
