.business-credentials{
    margin-left: 30px;
}

.input-value-field:focus{
    outline: 1px solid #BBA383 !important;
    border: none !important;
}

.select-show-asd:focus{
    outline: 1px solid #BBA383 !important;
    border: none !important;
}

.text-area-2:focus{
    outline: 1px solid #BBA383 !important;
    border: none !important;
}

@media screen and (min-height: 740px) and (max-height: 849px) {
    .section-profile{
      height: 100vh;
    }
  }

/* Step 4 business */

.attached-docs-header {
    margin-bottom: 30px !important;
    padding-right: 10px;
    margin-top: 50px;
  }
  
  .attached-docs-header h2 {
    display: inline;
    color: white;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
  }
  .t-doc-header{
    background-color: #1a1a1c !important;
    color: #d8e0e9 !important;
    border-bottom: 1px solid #313237 !important
  }