.dashboard-section {
  background-color: rgba(26, 26, 28, 255);
  color: white;
  border-radius: 24px;
  height: calc(100vh - 150px);
  margin-top: 10px;
  margin-right: 10px;
  padding: 34px 0px 34px 20px;
  overflow-y: scroll;
}
.mt-8 {
  margin-top: 50px !important;
}
.t-head-dashboard {
  color: white !important;
}
.title-2 .spinner-border {
  --bs-spinner-width: 1.5rem;
  --bs-spinner-height: 1.5rem;
  --bs-spinner-border-width: 0.11em;
}
