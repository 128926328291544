.refresh-icon{
    background-color: #313237;
    display: flex;
    justify-content: center;
    margin-bottom: 0px !important;
    align-items: center;
    padding-left: 12px !important;
  }
  .file-info-2{
    color: white;
    border: 1px solid white;
    border-radius: 6px;
    padding-left: 10px !important;
    min-width: 150px;
}
.file-img-icon{
  margin-right: 10px;
}