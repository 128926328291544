.create-campaign-section {
  width: 100%;
  background-color: #1a1a1c;
}

.form-section {
  background-color: #1a1a1c;
  padding: 80px 60px 20px 60px;
  min-height: 100vh;
}

.image-section {
  position: relative;
  background-image: url("../../assets/images/Step2.png") !important;
  width: 100%;
  height: 100%;
  padding: 30px 40px;
  background-size: cover;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.form-header {
  margin-bottom: 30px !important;
  padding-right: 10px;
}

.form-header h2 {
  display: inline;
  color: white;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
}

.form-header p {
  float: right;
  display: inline;
  color: #8e929a;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.toggle-tabs {
  display: flex;
  gap: 10px;
  border-radius: 5px;
  padding: 5px;
}

.toggle-tab {
  cursor: pointer;
  /* padding: 0px 5px; */
  color: #8e929a;
  font-size: 16px;
  /* font-weight: 600; */
  transition: all 0.3s ease-in-out;
}

.toggle-tab.active {
  color: #ffffff !important;
  border-bottom: 2px solid white;
}

/* Tab Content */
.tab-content {
  color: #ffffff;
}

.form-wrapper {
  display: flex;
}

.input-wrapper {
  margin-right: 20px;
  width: 300px;
}

.form-wrapper-3 {
  display: flex;
  margin-top: 25px;
  justify-content: space-between;
  margin-bottom: 30px !important;
}

/* .image-wrapper {
  width: 300px;
} */
.btn-area button {
  float: right;
}

.exit-icon {
  float: right;
  cursor: pointer;
  position: relative;
  z-index: 999;
}

.campaign-icon {
  position: absolute;
  bottom: 30px;
  right: 30px;
}

.image-wrapper .form-text {
  color: #8e929a !important;
  font-size: 12px !important;
  margin-left: 10px;
}

.toggle-button-wrap {
  /* margin-top: 7px; */
  display: flex;
}

.toggle-button-wrap .react-toggle-track {
  background-color: #8e929a;
  width: 40px;
  height: 24px;
}

.react-goolge-track {
  background-color: #8e929a !important;
}

.react-toggle-thumb {
  border: unset !important;
}

.react-toggle:hover .react-toggle-track {
  background-color: #8e929a !important;
}

.react-toggle--checked:hover .react-toggle-track {
  background-color: #72bd90 !important;
}

.react-toggle--checked .react-toggle-track {
  background-color: #72bd90 !important;
}

.react-toggle .react-toggle--checked:hover {
  background-color: #72bd90;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .form-section {
    background-color: #1a1a1c;
    padding: 60px 20px 20px 40px;
  }

  .form-wrapper div div input::placeholder {
    font-size: 14px;
    padding: unset !important;
  }

  .form-wrapper div div .i-field {
    padding: 6px 12px 7px !important;
  }

  .form-wrapper-2 div .form-control {
    font-size: 12px !important;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .form-wrapper div div input::placeholder {
    font-size: 14px;
    padding: unset !important;
  }

  .form-wrapper div div .i-field {
    padding: 6px 12px 7px !important;
  }

  .form-wrapper-2 div .form-control {
    font-size: 12px !important;
  }
}

@media only screen and (min-device-width: 1601px) {
  .input-label-f3s {
    font-size: 16px !important;
    color: white;
  }
  .form-section {
    background-color: #1a1a1c;
    padding: 100px 80px 20px 80px;
  }

  .image-section {
    height: 100vh;
  }

  .toggle-button-wrap label {
    font-size: 16px !important;
  }

  .form-label {
    font-size: 16px !important;
  }
}

.adjust-height-fhbsh {
  height: 100vh;
  overflow-y: scroll;
}

.adjust-height-fhbsh::-webkit-scrollbar {
  width: 2px;
  height: 8px;
}

.adjust-height-fhbsh::-webkit-scrollbar-track {
  background-color: #1a1a1c;
}

.adjust-height-fhbsh::-webkit-scrollbar-thumb {
  background-color: #1a1a1c;
}

.input-value-field {
  font-size: 12px;
  padding-left: 15px;
  height: 48px;
  border-radius: 5px;
  background-color: #1f1f22 !important;
}

.error-text-asd {
  font-size: 12px;
  color: #ff736c;
  font-weight: 400;
}

.text-area-2 {
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  background-color: #1f1f22;

  border-radius: 6px;
  font-size: 14px;
  height: 80px;
  color: white;
  padding: 15px 15px !important;
  border: 1px solid #8e929a;
  /* font-size: 12px; */
}
.text-area-2::placeholder {
  font-size: 12px;

  color: #8e929a;
}

.input-label-f3s {
  font-size: 14px !important;
  color: white;
  margin-left: 10px;
}

.categories-wrapper .select-show-asd,
.event-wrapper .select-show-asd,
.status-wrapper .select-show-asd,
.country .select-show-asd {
  height: 48px;
  border-radius: 5px;
  border: #8e929a 1px solid;
  cursor: pointer;
  color: #8e929a;
  display: flex;
  justify-content: start;
  align-items: center;
  padding-left: 15px;
  font-size: 12px !important;
  background-color: #1f1f22;
}

.arrowForSelect {
  position: absolute;
  right: 5px;
  top: 8px;
}

.wrapper-select {
  position: absolute;
  border: 5px;
  max-height: 250px;
  color: #8e929a;
  font-size: 12px;
  overflow-y: scroll;
  border-radius: 3px;
  background-color: #212529;
  margin-top: 8px;
}

.singleOptions-cs3 {
  height: 30px;
  display: flex;
  justify-content: start;
  align-items: center;
  cursor: pointer;
  font-size: 12px !important;
}

.singleOptions-cs3:hover {
  background-color: #8e929a;
  border-radius: 2px;
  color: #1a1a1c;
}

.wrapper-select::-webkit-scrollbar {
  width: 1px;
  height: 8px;
}

.wrapper-select::-webkit-scrollbar-track {
  background-color: #1a1a1c;
}

.wrapper-select::-webkit-scrollbar-thumb {
  background-color: #8e929a;
}

.img-desc-cni2 {
  color: #8e929a;
  font-size: 11px;
  padding-left: 10px;
}

.input-value-field::placeholder {
  font-size: 12px;
}
.input-label {
  margin-left: 5px;
}
.mb-8 {
  margin-bottom: 35px !important;
}
.mt-8 {
  margin-top: 45px !important;
}
.next-button-wrapper button {
  float: right;
}
.status-wrapper .singleOptions-cs3 {
  font-size: 12px !important;
  height: 40px !important;
  font-weight: 500;
  color: #949494;
}
.status-wrapper .singleOptions-cs3:hover {
  background-color: #bba383;
  color: white;
  font-weight: 500;
}
.categories-wrapper .singleOptions-cs3,
.event-wrapper .singleOptions-cs3 {
  font-size: 12px !important;
  height: 40px !important;
  font-weight: 500;
  color: #949494;
}
.categories-wrapper .singleOptions-cs3:hover,
.event-wrapper .singleOptions-cs3:hover {
  background-color: #bba383;
  color: white;
  font-weight: 500;
}
.f-campaign .react-toggle-thumb {
  left: 2px !important;
  top: 2.5px !important;
  width: 19.16px !important;
  height: 19.16px !important;
}
.f-campaign .react-toggle--checked .react-toggle-thumb {
  left: 18px !important;
}

.step-1 {
  background-color: rgba(187, 163, 131, 1);
  height: 4px;
  border-radius: 0px 2px 2px 0px;
}
.stage-bar {
  margin-top: 11px;
}

.categories-wrapper .wrapper-select {
  z-index: 3;
}
