.search-content-sb input {
  border: none !important;
  outline: none !important;
}
.email-rsvp:hover {
  text-decoration: underline;
}

.rsvp-icon {
  margin-right: 20px;
}

.guest-name {
  padding-left: 40px !important;
}
.guest-f-name {
  text-indent: 40px;
}
.all-events-dropdown {
  width: 280px;
  height: 40px !important;
  /* border-radius: 5px;
  border: #8e929a 1px solid;
  cursor: pointer;
  color: #8e929a;
  display: flex;
  justify-content: start;
  align-items: center;
  padding-left: 15px;
  padding-right: 10px;
  font-size: 12px !important;
  background-color: #1f1f22; */
}
 .all-events-dropdown .arrowForSelect {
    right: 3px;
    top: 2px;
} 
.all-events-dropdown .select-show-asd{
    height: 40px !important;
    /* outline: none ; */
} 