.search-icon-illusion {
  width: 5% !important;
  position: absolute;
  left: 10px;
  top: 50px;
  z-index: 3;
}

.b-address-line-2 .i-field {
  padding: 10px 12px 11px;
  background-color: #1f1f22 !important;
  color: white !important;
  border: 1px solid #8e929a !important;
}
.b-address-line-2 .i-field:focus {
  background-color: transparent !important;
  color: white !important;
}
.b-address-line-2 .i-field::placeholder {
  color: #8e929a !important;
  font-size: 14px;
}
.ticket-manager {
  margin-top: 20px !important;
}
.business-text {
  margin-top: 0.5rem;
  margin-bottom: 20px;
  font-size: 11px !important;
  font-weight: 400;
}
.form-header-business {
  margin-bottom: 30px !important;
  padding-right: 10px;
  margin-top: 20px;
}

.breaker svg {
  width: 100%;
  background-color: rgba(39, 43, 51, 1) !important;
}
.i-field {
  height: 48px !important;
  background-color: rgba(31, 31, 34, 1) !important;
  font-size: 12px !important;
  font-weight: 400;
}
.i-field::placeholder {
  font-size: 12px !important;
}
.form-wrapper .form-control {
  font-size: 12px !important;
}

.textBox-wrapper .form-control {
  height: 80px !important;
  font-size: 12px;
}
.form-wrapper div div input:focus {
  outline: 1px solid #bba383;
  border: none !important;
}
.text-box:focus {
  outline: 1px solid #bba383 !important;
  border: none !important;
}

.dropdown-wrapper .select-selected:focus {
  outline: 1px solid #bba383 !important;
  border: none !important;
}

.business-categories .custom-select .select-items {
  top: 55px;
  background-color: rgba(31, 31, 34, 1) !important;
}
.business-categories .custom-select .select-selected.select-arrow-active:after {
  top: 21px;
  border-color: #8e929a transparent transparent transparent;
}

.business-categories .custom-select .select-selected:after {
  top: 21px;
}

.business-countries .custom-select .select-selected:after {
  top: 21px !important;
}

.business-countries .custom-select .select-selected.select-arrow-active:after {
  top: 21px !important;
  border-color: #8e929a transparent transparent transparent !important;
}
.business-countries .custom-select .select-items {
  top: 55px !important;
  background-color: rgba(31, 31, 34, 1) !important;
}
.file-download-icon {
  border: 1px solid #ffff;
  height: 30px;
  width: 30px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
}
