* {
  font-family: Open Sans, sans-serif;
}

.truncate-one-line {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  inline-size: 150px;
  overflow-wrap: break-word; /* Set maximum width to 100% of the parent container */
}

.truncate-one-line-small {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  inline-size: 80px;
  overflow-wrap: break-word; /* Set maximum width to 100% of the parent container */
}

.truncate-two-lines-description {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  inline-size: 10rem;
}

.truncate-one-line-phone {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  inline-size: 120px;
  overflow-wrap: break-word; /* Set maximum width to 100% of the parent container */
}

.truncate-two-lines {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.benefit-table-section {
  background-color: rgba(26, 26, 28, 255);
  color: white;
  border-radius: 24px;
  height: 82vh;
  margin-top: 10px;
  margin-right: 10px;
  padding: 30px 0px 0px 20px;
}

.benefit-table-header-83h3 h4 {
  font-size: 20px;
  display: inline;
}

.benefit-search-bar {
  float: right;
}

.benefit-search-content {
  position: relative;
}

.benefit-search-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 15px;
  color: #8e929a;
}

.benefit-search-content input {
  max-width: 280px;
  width: 280px;
  height: 38px;
  background-color: #313237 !important;
  padding: 0px 10px 0px 45px !important;
  color: white;
  font-size: 12px;
  border: none !important;
  border-radius: 8px !important;
  outline: none;
}
.benefit-search-content input:focus {
  border: none !important;
  outline: none !important;
}
.benefit-search-content input::placeholder {
  font-size: 12px;
}

/* Table Footer*/

.table-footer .pagination {
  font-size: 11px;
  padding: 8px 0px 6px 5px;
  font-weight: 700;
  display: inline;
}

.table-footer div span {
  font-weight: 400 !important;
}

/* Table Css */
table {
  --bs-table-striped-bg: #1a1a1c !important;
  --bs-table-bg: #1a1a1c !important;
}

.thead {
  color: #d8e0e9 !important;
  background-color: #1a1a1c !important;
  cursor: pointer;
}

thead tr th {
  color: #d8e0e9 !important;
  font-size: 11px;
}

.t-header {
  cursor: pointer;
  background-color: #1a1a1c !important;
  color: #d8e0e9 !important;
  border-bottom: 1px solid #313237 !important;
}

.table-container-hd73 {
  height: 100%;
  border-radius: 10px;
  margin-top: 35px;
  overflow-y: auto;
  margin-bottom: 20px;
}

.table-container::-webkit-scrollbar {
  width: 4px;
  height: 5px;
}

.table-container::-webkit-scrollbar-thumb {
  background-color: #d8e0e9;
}

.table-container {
  scrollbar-color: #666668;
}

.t-body tr td {
  color: white;
  font-size: 12px;
  border-bottom: 1px solid #313237 !important;
  cursor: pointer;
}
.t-body tr {
  background-color: #1a1a1c;
}

.edit-button {
  position: relative;
  color: #8e929a;
  background-color: #1a1a1c;
  padding: 5px 8px;
  border: none;
  border-radius: 6px;
  font-size: 12px;
  font-weight: 700;
}

.edit-button:hover {
  background-color: #bba383;
  color: white;
  transition: background-color ease-out 0.3s;
}

.email-data {
  font-size: 10px;
  color: #8e929a;
  display: block;
}

/* Pagination */
.pagination {
  margin-right: 30px;
  display: inline;
  margin-left: 50px;
  float: right;
  font-size: 12px;
  color: white;
}

.pagination span {
  font-size: 11px;
  cursor: pointer;
  padding-right: 25px;
  font-weight: 700;
}

.page-no {
  display: inline;
  float: right;
  margin-right: 20px;
}

.dropdown-option {
  background-color: #313237;
  color: #d4d8dd;
  font-size: 12px;
  font-weight: 600;
}

.dropdown-option:hover {
  border-radius: 6px;
  background-color: #bba383;
  color: white;
  font-size: 12px;
  font-weight: 600;
}

.active-option {
  border-radius: 6px;
  color: #d4d8dd;
}

/* Results */
.result {
  float: right;
}

.result span {
  font-weight: 400 !important;
  margin-right: 50px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .table-container {
    height: 420px;
    border-radius: 10px;
    margin-bottom: 20px;
    position: relative;
    overflow: auto;
  }
  .benefit-search-content input {
    max-width: 280px;
    width: 260;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .table-container {
    height: 450px;
    border-radius: 10px;
    margin-bottom: 20px;
    position: relative;
    overflow: auto;
  }
}

@media only screen and (min-width: 1350px) and (max-width: 1500px) and (min-height: 850px) and (max-height: 950px) {
  .table-container {
    height: 550px;
    border-radius: 10px;
    margin-bottom: 20px;
    position: relative;
    overflow: auto;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1600px) {
  .table-container {
    height: 530px;
    border-radius: 10px;
    margin-bottom: 20px;
    position: relative;
    overflow: auto;
  }
}

@media only screen and (min-width: 1801px) {
  .table-container {
    height: 590px;
    border-radius: 10px;
    margin-bottom: 20px;
    position: relative;
    overflow: auto;
  }
}

.no-records-found {
  margin-top: 40px;
  font-size: 16px;
  color: white !important;
}
.first-click {
  color: #bba383;
  text-decoration: underline;
  font-size: 12px;
}
.third-line {
  color: #8e929a;
  font-size: 12px;
}
.singleOptions-cs3 {
  padding-left: 15px !important;
}

.t-benefit-desc {
  font-size: 10px;
  font-weight: 300;
}
.benefit-table-category {
  font-weight: 700;
}
.benefit-table-address {
  font-size: 10px;
  font-weight: 400;
}
.benefit-table-date {
  font-weight: 700;
}
.benefit-table-type {
  font-weight: 600;
}
.benefit-name {
  text-transform: capitalize !important;
}

.b-tab-url {
  color: white;
  font-size: 12px;
  font-weight: 600;
  text-decoration: none;
}
