.changed-password-section {
  width: 100%;
  height: 100vh;
  background-image: url("../../assets/images/membership-bg.png");
  background-size: cover;
  position: relative;
  background-repeat: no-repeat;
  background-position: 100% 100%;
  overflow-y: auto;
}
.changed-display-header {
  color: white;
  margin-bottom: 40px;
  padding: 40px 30px 0px 30px;
}

.changed-display-content {
  background-color: #1a1a1c;
  border-radius: 20px;
  width: 80%; /* Set width to 100% for responsiveness */
  max-width: 440px; /* Set a maximum width if needed */
  height: auto;
  margin: 240px auto 50px; /* Center the element vertically and horizontally */
  /* overflow-y:hidden;  */
  /* padding: 20px; Add padding for better spacing inside the container */
}

/* Media query for small screens (e.g., mobile devices) */
@media only screen and (max-width: 576px) {
  .changed-display-content {
    margin: 60px auto; /* Adjust margin for smaller screens */
  }
}

@media only screen and (max-height: 800px) {
  .changed-display-content {
    height: auto; /* Allow the height to adjust dynamically based on content */
    max-height: 80vh; /* Set a maximum height, e.g., 80% of the viewport height */
    margin: 170px auto 0px;
  }
}

@media only screen and (max-height: 670px) {
  .changed-display-content {
    height: auto; /* Allow the height to adjust dynamically based on content */
    max-height: 80vh; /* Set a maximum height, e.g., 80% of the viewport height */
    margin: 80px auto 10px;
    z-index: 30;
  }
  .changed-display-header {
    color: white;
    margin-bottom: 25px;
    padding: 40px 30px 0px 30px;
  }
}

.changed-display-header img {
  width: 180px;
  height: 26.18px;
}

.reset-form-parent {
  z-index: 100;
  padding-bottom: 5px;
}

.reset-login-form {
  padding: 0px 30px 0px 30px;
}

.f-c-label {
  margin-bottom: 0px !important;
  padding-left: 0px !important;
}

label {
  color: white !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  margin-bottom: 15px !important;
  padding-left: 10px !important;
}
input {
  background-color: transparent;
  color: white !important;
  /* height: 40x !important; */
  border: 1px solid #8e929a !important;
}
input:focus {
  background-color: transparent;
  color: white !important;
}
input::placeholder {
  color: #8e929a !important;
  font-size: 14px;
}
.changed-btn-wrapper {
  margin-top: 30px;
  margin-bottom: 40px;
  border-radius: 0px 0px 6px 6px;
  text-align: center;
  padding-bottom: 50px;
}

.changed-content {
  width: 100%;
  height: 100vh;
  background-image: linear-gradient(
    rgba(18, 18, 18, 1),
    rgba(18, 18, 18, 0.1),
    rgba(18, 18, 18, 0),
    rgba(18, 18, 18, 1)
  );
  z-index: 1;
  background-color: rgba(18, 18, 18, 0.6);
  background-size: cover;
  position: relative;
  background-repeat: no-repeat;
  background-position: 100% 100%;
  overflow-y: auto;
}
.form-password-text {
  display: block;
  color: #8e929a !important;
  font-size: 14px !important;
  font-weight: 600;
  padding-left: 10px;
}
.form-password-text ul li {
  font-weight: 400;
  font-size: 14px;
  color: #8e929a;
}
.error-color {
  color: #ff736c;
  font-size: 12px;
}
.footer {
  position: absolute;
  bottom: 0;
}

.changed-btn-wrapper button {
  border-radius: 6px;
  height: 40px;
  padding: 0px 169.1px;
  font-weight: 700;
  font-size: 12px;
}
.form-control:focus {
  border-color: white !important;
  box-shadow: none !important;
}
.reset-login-form input {
  background-color: #1f1f22;
  height: 48px !important;
}
.new-pass-form-text {
  display: block;
  color: #8e929a !important;
  font-size: 14px !important;
  padding-left: 10px;
  font-weight: 400;
  margin-bottom: 20px;
}
.changed-password-header {
  margin-bottom: 20px;
  color: white;
  padding-left: 30px;
}
.changed-password-header h6 {
  font-weight: 600;
  font-size: 20px;
}
.reset-login-form .mb-4 {
  margin-bottom: 10px !important;
}

.changed-message-text {
  font-size: 16px;
  color: rgba(255, 255, 255, 0.94);
  font-weight: 200;
  padding-left: 30px;
  padding-right: 20px;
  margin-bottom: 40px;
}
